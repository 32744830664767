/*!

*/

// variables and mixins
@import 'material-dashboard-pro-react/variables';
@import 'material-dashboard-pro-react/mixins';
@import 'material-dashboard-pro-react/shadows';

// plugin css
@import 'material-dashboard-pro-react/plugins/plugin-nouislider';
@import 'material-dashboard-pro-react/plugins/plugin-perfect-scrollbar';
@import 'material-dashboard-pro-react/plugins/plugin-react-datetime';
@import 'material-dashboard-pro-react/plugins/plugin-react-bootstrap-sweetalert';
@import 'material-dashboard-pro-react/plugins/plugin-react-chartist';
@import 'material-dashboard-pro-react/plugins/plugin-react-big-calendar';
@import 'material-dashboard-pro-react/plugins/plugin-react-jvectormap';
@import 'material-dashboard-pro-react/plugins/plugin-react-table';
@import 'material-dashboard-pro-react/plugins/plugin-react-tagsinput';

// Core CSS
@import 'material-dashboard-pro-react/misc';
@import 'material-dashboard-pro-react/fileupload';
@import 'material-dashboard-pro-react/fixed-plugin';
